import './scss/styles.scss';

document.addEventListener("DOMContentLoaded", function () {
    const rabbit = document.querySelector('.rabbit');
    const tail = document.querySelector('.tail');

    // Funkcja uruchamiająca animację
    function startAnimation() {
        rabbit.classList.add('hop-animation');
        tail.classList.add('kick-animation');
        rabbit.addEventListener('animationend', () => {
            // Pobrana aktualna pozycja królika (lub dowolny atrybut)
            const computedStyle = window.getComputedStyle(rabbit);
            const transform = computedStyle.transform;
    
            // Wyświetlamy w konsoli aktualną wartość transform
            console.log('Aktualna pozycja (transform):', transform);
        });
        // Nasłuchujemy na zakończenie każdej animacji skoku
        rabbit.addEventListener('animationend', (e) => {
            // Pierwszy skok (hop)
            if (e.animationName === "hop") {
                rabbit.style.transform = 'rotate(0deg) translate(4em, 0)';
                rabbit.classList.remove('hop-animation');
                rabbit.classList.add('hop-animation-2');
                resetKickAnimation();
            }

            // Drugi skok (hop2)
            else if (e.animationName === "hop2") {
                rabbit.style.transform = 'rotate(0deg) translate(10em, 0)';
                rabbit.classList.remove('hop-animation-2');
                rabbit.classList.add('hop-animation-3');
                resetKickAnimation();
            }

            // Trzeci skok (hop3)
            else if (e.animationName === "hop3") {
                rabbit.style.transform = 'rotate(0deg) translate(15em, 0)';
                rabbit.classList.remove('hop-animation-3');
                rabbit.classList.add('hop-animation-4');
                resetKickAnimation();
            }

            // Czwarty skok (hop4)
            else if (e.animationName === "hop4") {
                rabbit.classList.remove('hop-animation-4');
                rabbit.style.transform = 'rotate(0deg) translate(22em, 3em) scale(0)';
                

                // Dodaj animację "wpadającego królika"
                //rabbit.classList.add('fall-animation');
            }
        });

        // Funkcja do resetowania i ponownego uruchomienia animacji kopnięcia
        function resetKickAnimation() {
            tail.classList.remove('kick-animation');
            setTimeout(() => {
                tail.classList.add('kick-animation');
            }, 10); // Drobne opóźnienie, aby zresetować animację ogona
        }
    }

    // Uruchamiamy animację po 1 sekundzie
    setTimeout(startAnimation, 1000);
});